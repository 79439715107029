<template>
    <BaseScene 
        name="face-manipulation" 
        :background="ResourceManager.Get('scenes.facemanipulation')"
        @firstLoad="onFirstLoad" 
        @enter="OnEnterScene"
        @leave="OnLeaveScene"
    >
        <div v-show="!imageLoaded">
            <input type="file" id="imageInput" ref="imageInput"/>
            <canvas ref="canvas" id="imageCanvas" @click="onCanvasClicked"></canvas>
            <!-- <canvas id="imageCanvas" width="512" height="384"></canvas> -->

            <img @click="onUploadFileClicked" class="upload-button" :src="ResourceManager.Get('facemanipulation.upload')">

            <a :href="downloadButtonRef" download="Bild.png" @click="onSaveImageClicked">
                <img class="save-button" :src="ResourceManager.Get('facemanipulation.save')">                
            </a>

            <div class="gui" :class="{visible: baseImage != null}">
                <!-- <perfect-scrollbar :options="{swipeEasing: false, wheelPropagation: true}">                         -->
                    <div class="dividing-headline">{{ cms.get('translations').get('selection') }}</div>
                    <p class="description" v-html="cms.get('translations').get('facemanipulation-explanation-1')"></p>

                    <div class="item-editing-panel">
                        <template v-if="activeItem">
                            <div class="slider">
                                <label><img :src="ResourceManager.Get('facemanipulation.x-axis')"></label>
                                <input 
                                    type="range" 
                                    :min="0" 
                                    :max="this.ctx.canvas.width + activeItem.w" 
                                    v-model="activeItem.x"
                                    @input="onItemXChanged($event)"
                                >
                            </div>
                            <div class="slider">
                                <label><img :src="ResourceManager.Get('facemanipulation.y-axis')"></label>
                                <input 
                                    type="range" 
                                    :min="0" 
                                    :max="this.ctx.canvas.height + activeItem.h" 
                                    v-model="activeItem.y"
                                    @input="onItemYChanged($event)"
                                >
                            </div>
                            <div class="slider">
                                <label><img :src="ResourceManager.Get('facemanipulation.resize')"></label>
                                <input type="range" min="0.1" max="3" v-model="activeItem.s" step="0.01" @input="onItemSizeChanged($event)">
                            </div>
                            <div class="slider">
                                <label><img :src="ResourceManager.Get('facemanipulation.rotate')"></label>
                                <input type="range" min="0" max="360" v-model="activeItem.r" step="0.01" @input="onItemRotationChanged($event)">
                            </div>

                            <div class="color-picker" v-if="activeItem.isDyable || !activeItem">
                                <div 
                                    v-for="color in availableColors"
                                    :key="color"
                                    class="color" 
                                    :class="{active: selectedColor == color}" 
                                    @click="selectColor(color)" 
                                    :style="{background: color}"
                                >
                                </div>
                            </div>

                            <button class="delete-button" @click="deleteItem(activeItem)">
                                <font-awesome-icon icon="trash" size="2x"/>    
                            </button>                        
                        </template>
                        <template v-else>
                                <div class="slider">
                                    <label><img :src="ResourceManager.Get('facemanipulation.x-axis')"></label>
                                    <input type="range" disabled>
                                </div>
                                <div class="slider">
                                    <label><img :src="ResourceManager.Get('facemanipulation.y-axis')"></label>
                                    <input type="range" disabled>
                                </div>
                                <div class="slider">
                                    <label><img :src="ResourceManager.Get('facemanipulation.resize')"></label>
                                    <input type="range" disabled>
                                </div>
                                <div class="slider">
                                    <label><img :src="ResourceManager.Get('facemanipulation.rotate')"></label>
                                    <input type="range" disabled>
                                </div>
                                <button class="delete-button" disabled>
                                    <font-awesome-icon icon="trash" size="2x"/>
                                </button>
                        </template>
                    </div>

                    <!-- <Vue3Slider v-model="myNumber" color="#FB278D" track-color="#FEFEFE" /> -->
                    <!-- <VueSlider v-model="activeItem.s" v-if="activeItem"/> -->

                    <div class="dividing-headline">{{ cms.get('translations').get('objects') }}</div>
                    <p class="description" v-html="cms.get('translations').get('facemanipulation-explanation-2')"></p>
                    <ul class="item-list">
                        <li v-for="item in items" :key="item.name" @click="onItemSelected(item)">
                            <img :src="item.path">
                        </li>
                    </ul>
                <!-- </perfect-scrollbar>    -->
            </div>            
        </div>
        <div>

        </div>
    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import {Howl} from 'howler';
import ResourceManager from "@/classes/resource-manager";
import { ref } from 'vue';
import axios from "axios";
// import SceneTrigger from '../triggers/SceneTrigger.vue';

export default {
    inject: {
        cms: {
            default: null,
        },
    },    
    components: {
        BaseScene,
        // SceneTrigger
    },
    setup() {
        let canvas = ref(null);
        let imageInput = ref(null);

        return {
            canvas,
            imageInput
        };
    },
    data() {
        return {
            ResourceManager,
            imageLoaded: false,
            ctx: null,
            items: [
                {
                    name: 'Eyebrow 1',
                    path: require('@/assets/facemanipulation/items/eyebrow-1.svg'),
                    initialW: 200,
                    initialH: 60,
                    svg: ''
                },
                {
                    name: 'Eyebrow 2',
                    path: require('@/assets/facemanipulation/items/eyebrow-2.svg'),
                    initialW: 200,
                    initialH: 60,
                    svg: ''
                },
                {
                    name: 'Eyebrow 3',
                    path: require('@/assets/facemanipulation/items/eyebrow-3.svg'),
                    initialW: 200,
                    initialH: 60,
                    svg: ''
                },
                {
                    name: 'Glasses 1',
                    path: require('@/assets/facemanipulation/items/glasses-1.svg'),
                    initialW: 200,
                    initialH: 110,
                    svg: ''
                },
                {
                    name: 'Glasses 2',
                    path: require('@/assets/facemanipulation/items/glasses-2.svg'),
                    initialW: 200,
                    initialH: 110,
                    svg: ''
                },
                {
                    name: 'Glasses 3',
                    path: require('@/assets/facemanipulation/items/glasses-3.svg'),
                    initialW: 200,
                    initialH: 110,
                    svg: ''
                },
                {
                    name: 'Glasses 4',
                    path: require('@/assets/facemanipulation/items/glasses-4.svg'),
                    initialW: 200,
                    initialH: 110,
                    svg: ''
                },
                {
                    name: 'Glasses 5',
                    path: require('@/assets/facemanipulation/items/glasses-5.svg'),
                    initialW: 200,
                    initialH: 110,
                    svg: ''
                },
                {
                    name: 'Glasses 6',
                    path: require('@/assets/facemanipulation/items/glasses-6.svg'),
                    initialW: 200,
                    initialH: 110,
                    svg: ''
                },
                {
                    name: 'Glasses 7',
                    path: require('@/assets/facemanipulation/items/glasses-7.svg'),
                    initialW: 200,
                    initialH: 110,
                    svg: ''
                },
                {
                    name: 'Glasses 8.1',
                    path: require('@/assets/facemanipulation/items/glasses-8-1.svg'),
                    initialW: 230,
                    initialH: 90,
                    svg: ''
                },
                {
                    name: 'Glasses 8.2',
                    path: require('@/assets/facemanipulation/items/glasses-8-2.svg'),
                    initialW: 230,
                    initialH: 90,
                    svg: ''
                },
                {
                    name: 'Glasses 8.3',
                    path: require('@/assets/facemanipulation/items/glasses-8-3.svg'),
                    initialW: 230,
                    initialH: 90,
                    svg: ''
                },
                {
                    name: 'Glasses 8.4',
                    path: require('@/assets/facemanipulation/items/glasses-8-4.svg'),
                    initialW: 230,
                    initialH: 90,
                    svg: ''
                },
                {
                    name: 'Glasses 8.5',
                    path: require('@/assets/facemanipulation/items/glasses-8-5.svg'),
                    initialW: 230,
                    initialH: 90,
                    svg: ''
                },
                {
                    name: 'Glasses 8.6',
                    path: require('@/assets/facemanipulation/items/glasses-8-6.svg'),
                    initialW: 230,
                    initialH: 90,
                    svg: ''
                },
                {
                    name: 'Glasses 8.7',
                    path: require('@/assets/facemanipulation/items/glasses-8-7.svg'),
                    initialW: 230,
                    initialH: 90,
                    svg: ''
                },
                {
                    name: 'Glasses 9.1',
                    path: require('@/assets/facemanipulation/items/glasses-9-1.svg'),
                    initialW: 220,
                    initialH: 210,
                    svg: ''
                },
                {
                    name: 'Glasses 9.2',
                    path: require('@/assets/facemanipulation/items/glasses-9-2.svg'),
                    initialW: 220,
                    initialH: 210,
                    svg: ''
                },
                {
                    name: 'Glasses 9.3',
                    path: require('@/assets/facemanipulation/items/glasses-9-3.svg'),
                    initialW: 220,
                    initialH: 210,
                    svg: ''
                },
                {
                    name: 'Glasses 9.4',
                    path: require('@/assets/facemanipulation/items/glasses-9-4.svg'),
                    initialW: 220,
                    initialH: 210,
                    svg: ''
                },
                {
                    name: 'Glasses 10',
                    path: require('@/assets/facemanipulation/items/glasses-10.svg'),
                    initialW: 200,
                    initialH: 110,
                    svg: ''
                },
                {
                    name: 'Glasses 11',
                    path: require('@/assets/facemanipulation/items/glasses-11.svg'),
                    initialW: 200,
                    initialH: 110,
                    svg: ''
                },
                {
                    name: 'Glasses 12',
                    path: require('@/assets/facemanipulation/items/glasses-12.svg'),
                    initialW: 102,
                    initialH: 135,
                    svg: ''
                },
                {
                    name: 'Mouth 1.1',
                    path: require('@/assets/facemanipulation/items/mouth-1-1.svg'),
                    initialW: 100,
                    initialH: 100,
                    svg: ''
                },
                {
                    name: 'Mouth 1.2',
                    path: require('@/assets/facemanipulation/items/mouth-1-2.svg'),
                    initialW: 100,
                    initialH: 100,
                    svg: ''
                },
                {
                    name: 'Mouth 1.3',
                    path: require('@/assets/facemanipulation/items/mouth-1-3.svg'),
                    initialW: 100,
                    initialH: 100,
                    svg: ''
                },
                {
                    name: 'Mouth 1.4',
                    path: require('@/assets/facemanipulation/items/mouth-1-4.svg'),
                    initialW: 100,
                    initialH: 100,
                    svg: ''
                },
                {
                    name: 'Mouth 1.5',
                    path: require('@/assets/facemanipulation/items/mouth-1-5.svg'),
                    initialW: 100,
                    initialH: 100,
                    svg: ''
                },
                {
                    name: 'Mouth 1.7',
                    path: require('@/assets/facemanipulation/items/mouth-1-7.svg'),
                    initialW: 100,
                    initialH: 100,
                    svg: ''
                },                
                {
                    name: 'Mouth 2',
                    path: require('@/assets/facemanipulation/items/mouth-2.svg'),
                    initialW: 120,
                    initialH: 80,
                    svg: ''
                },                
                {
                    name: 'Mouth 3',
                    path: require('@/assets/facemanipulation/items/mouth-3.svg'),
                    initialW: 120,
                    initialH: 60,
                    svg: ''
                },                
                {
                    name: 'Bunny Ears 1',
                    path: require('@/assets/facemanipulation/items/bunny-ears-1.svg'),
                    initialW: 323,
                    initialH: 173,
                    svg: ''
                },
                {
                    name: 'Bunny Ears 2',
                    path: require('@/assets/facemanipulation/items/bunny-ears-2.svg'),
                    initialW: 323,
                    initialH: 173,
                    svg: ''
                },
                {
                    name: 'Bunny Ears 3',
                    path: require('@/assets/facemanipulation/items/bunny-ears-3.svg'),
                    initialW: 323,
                    initialH: 173,
                    svg: ''
                },
                {
                    name: 'Bunny Ears 4',
                    path: require('@/assets/facemanipulation/items/bunny-ears-4.svg'),
                    initialW: 323,
                    initialH: 173,
                    svg: ''
                },
                {
                    name: 'Bunny Ears 5',
                    path: require('@/assets/facemanipulation/items/bunny-ears-5.svg'),
                    initialW: 323,
                    initialH: 173,
                    svg: ''
                },
                {
                    name: 'Beard 1',
                    path: require('@/assets/facemanipulation/items/beard-1.svg'),
                    initialW: 100,
                    initialH: 30,
                    svg: ''
                },
                {
                    name: 'Beard 2',
                    path: require('@/assets/facemanipulation/items/beard-2.svg'),
                    initialW: 100,
                    initialH: 30,
                    svg: ''
                },
                {
                    name: 'Beard 3',
                    path: require('@/assets/facemanipulation/items/beard-3.svg'),
                    initialW: 100,
                    initialH: 30,
                    svg: ''
                },
                {
                    name: 'Beard 4',
                    path: require('@/assets/facemanipulation/items/beard-4.svg'),
                    initialW: 120,
                    initialH: 80,
                    svg: ''
                },
                {
                    name: 'Beard 5',
                    path: require('@/assets/facemanipulation/items/beard-5.svg'),
                    initialW: 100,
                    initialH: 30,
                    svg: ''
                },                                
                {
                    name: 'Hat 1',
                    path: require('@/assets/facemanipulation/items/hat-1.svg'),
                    initialW: 167,
                    initialH: 200,
                    svg: ''
                },
                {
                    name: 'Hat 2',
                    path: require('@/assets/facemanipulation/items/hat-2.svg'),
                    initialW: 200,
                    initialH: 110,
                    svg: ''
                },
                {
                    name: 'Hat 3',
                    path: require('@/assets/facemanipulation/items/hat-3.svg'),
                    initialW: 200,
                    initialH: 150,
                    svg: ''
                },
                {
                    name: 'Mask 1',
                    path: require('@/assets/facemanipulation/items/mask-1.svg'),
                    initialW: 400,
                    initialH: 220,
                    svg: ''
                },
                {
                    name: 'Mask 2',
                    path: require('@/assets/facemanipulation/items/mask-2.svg'),
                    initialW: 300,
                    initialH: 90,
                    svg: ''
                },
                {
                    name: 'Mask 3',
                    path: require('@/assets/facemanipulation/items/mask-3.svg'),
                    initialW: 300,
                    initialH: 90,
                    svg: ''
                },
                {
                    name: 'Tie 1',
                    path: require('@/assets/facemanipulation/items/tie-1.svg'),
                    initialW: 100,
                    initialH: 300,
                    svg: ''
                },
                {
                    name: 'Bow Tie 1',
                    path: require('@/assets/facemanipulation/items/bow-tie-1.svg'),
                    initialW: 100,
                    initialH: 45,
                    svg: ''
                },
                {
                    name: 'Wig 1',
                    path: require('@/assets/facemanipulation/items/wig-1.svg'),
                    initialW: 300,
                    initialH: 260,
                    svg: ''
                },
            ],
            baseImage: null,
            usedItems: [],
            editingPanelModel: {
                itemX: 0,
                itemY: 0
            },
            downloadButtonRef: "",
            activeItem: null,
            availableColors: [
                '#2c3e50',
                '#ecf0f1',
                '#16a085',
                '#f1c40f',
                '#e67e22',
                '#c0392b',
                '#9b59b6',
                '#3498db',
                '#e056fd',
                '#6ab04c',
                '#fda7df'
            ],
            selectedColor: ''
        };
    },
    mounted() {
        this.selectedColor = this.availableColors[0];

        window.addEventListener('resize', () => {
            this.resizeCanvas();
        });

        // for (const input of document.querySelectorAll('input[type="range"]')) {
        //     input.addEventListener('input', e => {
        //         console.log(e);
        //     });
        // }

        for (let item of this.items) {
            axios.get(item.path, {
                responseType: 'arraybuffer'
            })
            .then(response => {
               let b64 = Buffer.from(response.data, 'binary').toString('utf-8');
               item.svg = b64;
            });
        }
    },
    methods: {
        updateBaseImage(e) {
            if (!e.target.files || !e.target.files[0]) {
                console.error("fileList is invalid: " + e.target.files);
                return;
            }

            const fr = new FileReader();
            fr.addEventListener("load", (fre) => {
                const img = new Image();
                img.addEventListener("load", () => {
                    this.baseImage = img;
                    this.repaintCanvas();
                });

                img.src = fre.target.result;                
            });

            fr.readAsDataURL(e.target.files[0]);
        },
        onFirstLoad() {
            this.$nextTick(() => {
                this.$refs.imageInput?.addEventListener("change", this.updateBaseImage);
                this.ctx = this.$refs.canvas.getContext("2d");
                this.resizeCanvas();                
            });
        },
        onItemSelected(item) {
            const img = new Image();

            const listener = () => {                
                const newItem = {
                    img: img,
                    x: this.ctx.canvas.width / 2 + item.initialW / 2,
                    y: this.ctx.canvas.height / 2 + item.initialH / 2,
                    r: 0,
                    s: 1,
                    initialW: item.initialW,
                    initialH: item.initialH,
                    w: item.initialW,
                    h: item.initialH,
                    color: this.selectedColor,
                    svg: item.svg,
                    isDyable: item.svg.indexOf("#000000") != -1
                };

                this.activeItem = newItem;
                this.usedItems.push(newItem);

                this.repaintCanvas();
                img.removeEventListener("load", listener);
                img.addEventListener("load", () => {
                    this.repaintCanvas();
                });
            };

            img.addEventListener("load", listener);

            let src = item.svg;
            let b64 = Buffer.from(src.replaceAll(/#000000/g, this.selectedColor, 'utf8')).toString('base64');

            img.src = 'data:image/svg+xml;base64,' + b64;
        },
        onItemXChanged(event) {
            this.activeItem.x = +event.target.value;
            this.repaintCanvas();
        },
        onItemYChanged(event) {
            this.activeItem.y = +event.target.value;
            this.repaintCanvas();
        },
        onItemSizeChanged(event) {
            this.activeItem.s = +event.target.value;
            this.repaintCanvas();
        },
        onItemRotationChanged(event) {
            this.activeItem.r = +event.target.value;
            this.repaintCanvas();
        },
        repaintCanvas() {
            this.repaintBaseImage();         

            for (let item of this.usedItems) {

                const rad = item.r * (Math.PI / 180);

                const cx = item.x - item.w / 2;
                const cy = item.y - item.h / 2;

                this.ctx.globalAlpha = 1;
                this.ctx.setTransform(1, 0, 0, 1, 0, 0);
                this.ctx.translate(cx, cy);
                this.ctx.rotate(rad);
                this.ctx.scale(item.s, item.s);
                this.ctx.translate(-cx, -cy);

                this.ctx.drawImage(item.img, cx - item.w / 2, cy - item.h / 2, item.w, item.h);

                if (this.activeItem == item) {
                    const boxPadding = 10;
    
                    this.ctx.strokeStyle = "#cccccc";
                    this.ctx.beginPath();
                    this.ctx.setLineDash([10, 5]);
                    this.ctx.strokeRect(
                        cx - item.w / 2 - boxPadding,
                        cy - item.h / 2 - boxPadding,
                        item.w + boxPadding * 2,
                        item.h + boxPadding * 2
                    );
                }
            }
        },
        onCanvasClicked(event) {
            const x = event.offsetX;
            const y = event.offsetY;

            for (const item of this.usedItems) {
                if (x <= item.x && x >= item.x - item.w && y < item.y && y >= item.y - item.h) {
                    this.setActiveItem(item);
                    return true;
                }
            }

            this.setActiveItem(null);
            return false;
        },
        selectColor(color) {
            this.selectedColor = color;

            if (this.activeItem) {
                let src = this.activeItem.svg;
                let b64 = Buffer.from(src.replaceAll(/#000000/g, this.selectedColor, 'utf8')).toString('base64');

                this.activeItem.img.src = 'data:image/svg+xml;base64,' + b64; 
                
                this.repaintCanvas();
            }
        },
        repaintBaseImage() {
            if (!this.baseImage) {
                return;
            }

            let img = this.baseImage;

            let cw = this.ctx.canvas.width;
            let ch = this.ctx.canvas.height;

            this.ctx.setTransform(1, 0, 0, 1, 0, 0);
            this.ctx.clearRect(0, 0, cw, ch);

            if (img.width > img.height) {
                let fixedH = cw * img.height / img.width;
                this.ctx.drawImage(img, 0, 0, img.width, img.height, 0, ch / 2 - fixedH / 2, cw, fixedH);
            } else {
                let fixedW = ch * img.width / img.height;
                this.ctx.drawImage(img, 0, 0, img.width, img.height, cw / 2 - fixedW / 2, 0, fixedW, ch);
            }            
        },
        onSaveImageClicked() {
            this.activeItem = null;

            this.repaintCanvas();

            this.downloadButtonRef = 
                this.ctx.canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        },
        setActiveItem(item) {
            this.activeItem = item;
            this.repaintCanvas();
        },
        deleteItem(item) {
            if (item == this.activeItem) {
                this.activeItem = null;
            }

            const idx = this.usedItems.indexOf(item);
            if (idx != -1) {
                this.usedItems.splice(idx, 1);
                this.repaintCanvas();
            }
        },
        resizeCanvas() {
            if (!this.$refs.canvas) {
                return;
            }

            const cvBox = this.$refs.canvas.getBoundingClientRect();
            this.$refs.canvas.setAttribute('height',  cvBox.height);
            this.$refs.canvas.setAttribute('width', cvBox.width);
            
            this.repaintCanvas();
        },
        onUploadFileClicked() {
            this.$refs.imageInput.click();
        },
        OnEnterScene() {
            if (!this.bgm) {
                this.bgm = new Howl({
                    src: [ResourceManager.Get('laughsongs.torsten')],
                    loop: true,
                    volume: 1,
                    html5: true
                });
            }
    
            if (!this.bgm.playing()) {
                this.bgm.play();
            }

            this.onFirstLoad();
        },
        OnLeaveScene() {
            this.bgm.stop();
        }
    },
    computed: {

    }
}
</script>

<style lang="scss">
.scene-face-manipulation {
    #imageInput {
        display: none;
    }

    canvas,
    .upload-button,
    .save-button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;      
    }

    canvas {
        // border: 1px solid red;  
        @include width-ar(24.2);
        @include xy-ar(-13.4, -2);
        @include height-ar(30.9);
        cursor: default;
    }    

    .upload-button {
        @include width-ar(8);
        @include xy-ar(-38, -8);
    }

    .save-button {
        @include width-ar(8);
        @include xy-ar(-38, 4);
    }

    .gui {
        background-image: url("../../assets/images/overlay-right.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: fixed;
        width: 30vw;
        height: 100vh;
        right: 0;
        top: 0;
        // background: #dfdfdf;
        padding: 20px 5vw;
        right: -30vw;
        transition: .35s;
        overflow-y: scroll;
        padding-right: calc(5vw + 40px);

        &.visible {
            right: 0;
        }

        .ps {
            height: 100%;
            padding-right: 40px;
        }

        .dividing-headline {
            @extend .font-hl;
            font-size: 40px;
            margin-bottom: 10px;
            border-bottom: 2px solid $main;
            padding-bottom: 13px;     
            color: $main;      
            font-weight: bold; 
        }

        .description {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;            
        }

        .delete-button {
            border: 0;
            outline: 0;
            padding: 10px 20px;
            background: $hl-1;
            color: white;
            cursor: pointer;

            &[disabled] {
                pointer-events: none;
                background: lightgray;

                color: darkgray;
            }
        }

        .color-picker {
            display: flex;
            margin: 0 -3px;
            margin-bottom: 20px;
            flex-wrap: wrap;

            .color {
                width: 36px;
                height: 36px;
                margin: 3px;
                cursor: pointer;
                border: 1px solid lightgray;
                flex: 0 0 36px;

                &.active {
                    border: 1px solid black;
                }
            }
        }
    }

    .item-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        margin: 0 -3px;
        padding-bottom: 50px;

        li {
            width: 64px;
            height: 64px;
            background: darken(#dfdfdf, 10%);
            cursor: pointer;
            margin: 3px;
            padding: 3px;
            transition: .5s;

            &:hover {
                background: darken(#dfdfdf, 30%);
            }            

            img {
                width: 100%;
                height: 100%;
                object-fit: fill;
                object-position: center;
            }
        }
    }

    .item-editing-panel {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .slider {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            label {
                margin-right: 10px;
                color: darkgray;
                display: flex;

                img {
                    width: 36px;
                }
            }

            input {
                width: 100%;
            }
        }
    }
}

@media (max-width: 800px) {
    .scene-face-manipulation {
        .item-list li {
            width: 32px;
            height: 32px;
        }

        .gui {
            width: 40vw;

            .color-picker {
                .color {
                    width: 24px;
                    height: 24px;
                }
            }

            .dividing-headline {
                font-size: 30px;
            }

            .delete-button {
                padding: 5px 20px;
            }
        }

        .item-editing-panel {
            .slider {
                label {
                    img {
                        width: 26px;
                    }
                }
            }
        }
    }
}
</style>